import React from "react";
import "./CompetitionSection.css";
import "./CompetitionSectionData";
import "./HeroSection.css";
import "./CompeteHeroSection.css";
import "./GameCard.css";
import { Button } from "./Button";
import Fade from "react-reveal/Fade";
import LazyAnimation from "./LazyAnimation";

function CompetitionSectionShare({
  imgMonth,
  monthCaption,
  imgWeek,
  weekCaption,
  gameTitleMonth,
  gameTitleWeek,
  subTitleMonth,
  subTitleWeek,
  alt,
  extraPad,
  imgStart,
}) {
  return (
    <>
      <div className={extraPad ? "hero-section-text-extra-padding hero-section-gray-bg" : "hero-section-gray-bg"}>
      <Fade bottom duration={800}>
             <h1 className="active-comp-header">
               Win Real Money
             </h1>
           </Fade>
        <div
          className="hero-section-text"
        >
          <div className="hero-section-container">
            <div
              className="hero-section-row"
              style={{
                display: "flex",
                flexDirection: imgStart === "start" ? "row-reverse" : "row",
              }}
            >
              <div className="hero-section-col">
              <div className="active-comp-caption hero-section-text-dark">{weekCaption}</div>
                <LazyAnimation></LazyAnimation>
                <Fade bottom cascade duration={800}>
                  <div className="hero-section-text-wrapper">
                    <h1
                      className="hero-section-heading hero-section-text-dark"
                    >
                      {gameTitleWeek}
                    </h1>
                    <p
                      className="hero-section-subtitle hero-section-text-dark"
                    >
                      {subTitleWeek}
                    </p>
                    <a href="https://virtuix.com/omni-arena-leaderboards" target="_blank" rel="noreferrer">
                    <Button buttonSize="btn-wide" buttonColor="green">
                See Leaderboard{" "}
                <div className="gamecard-right-arrow">
                  <img src="/images/arrow-icon-now.svg" alt="arrow" />
                </div>
              </Button>
              </a>
                  </div>
                </Fade>
              </div>
              <div className="hero-section-col">
                <div className="hero-section-img-wrapper">
                  <img src={imgWeek} alt={alt} className="active-comp-img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// {
//   return (
//     <>
//       <div className="active-comp-bg">
//         <div className="active-comp-container">
//           <Fade bottom duration={800}>
//             <h1 className="active-comp-header">
//               Win Real Money
//             </h1>
//           </Fade>
//           <div className="active-comp-row">
//             <div className="active-comp-col">
//               <img src={imgMonth} alt={alt} className="active-comp-img" />
//               <Fade bottom duration={800}>
//                 <div className="active-comp-caption">{monthCaption}</div>
//               </Fade>
//               <LazyAnimation></LazyAnimation>
//               <Fade bottom duration={800}>
//                 <div className="active-comp-title">{gameTitleMonth}</div>
//               </Fade>
//               <Fade bottom duration={800}>
//                 <div className="active-comp-subtitle">{subTitleMonth}</div>
//               </Fade>
//               <a href="https://virtuix.com/omni-arena-leaderboards">
//                 <Button buttonSize="btn-wide" buttonColor="green">
//                   {buttonLabel}{" "}
//                   <div className="active-comp-right-arrow">
//                     <img src="/images/arrow-icon-now.svg" alt="arrow" />
//                   </div>
//                 </Button>
//               </a>
//             </div>
//             <div className="active-comp-col">
//               <img src={imgWeek} alt={alt} className="active-comp-img" />
//               <Fade bottom duration={800}>
//                 <div className="active-comp-caption">{weekCaption}</div>
//               </Fade>
//               <LazyAnimation></LazyAnimation>
//               <Fade bottom duration={800}>
//                 <div className="active-comp-title">{gameTitleWeek}</div>
//               </Fade>
//               <Fade bottom duration={800}>
//                 <div className="active-comp-subtitle">{subTitleWeek}</div>
//               </Fade>
//               <a href="https://virtuix.com/omni-arena-leaderboards">
//                 <Button buttonSize="btn-wide" buttonColor="green">
//                   {buttonLabel}{" "}
//                   <div className="active-comp-right-arrow">
//                     <img src="/images/arrow-icon-now.svg" alt="arrow" />
//                   </div>
//                 </Button>
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

export default CompetitionSectionShare;
